<template>
  <div class="controls">
    <div
      class="layer--input-with-units"
      :class="{ 'layer--input-with-units--disabled': material.isDisabled }"
      v-if="isInput"
    >
      <div class="value" v-if="material.isDisabled">{{ material.value }}</div>
      <input type="number" v-else min="0" max="1000" step="0.1" v-model.number="computedValue" />
      <span class="units">{{ getUnit }}</span>
    </div>
    <div
      class="layer-select"
      :class="{ 'layer-select--disabled': isOptionDisabled }"
      v-if="isSelect"
    >
      <div class="value" v-if="isOptionDisabled">
        {{ currentOptions[0] }} {{ $t('message.units.mm') }}
      </div>
      <select v-model.number="computedValue" v-else>
        <option
          :value="option"
          :name="`child-layer-${layerIndex}-number-${itemIndex}`"
          v-for="(option, n) in currentOptions"
          :key="`option-${n}-layer-${layerIndex}`"
        >
          {{ option }} {{ $t('message.units.mm') }}
        </option>
      </select>
    </div>
    <div
        v-if="isMultilayerMode"
        class="layer--input-with-units layer--input-square"
        :content="$t('message.system.multilayer.squareHint')"
        v-tippy="{ placement: 'top', maxWidth: 200, duration: 1000 }"
    >
      <input type="number" min="0" max="1000" step="0.1" v-model="computedSquare" />
      <span class="units">{{ $t('message.units.m2') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  props: {
    item: Object,
    layerIndex: Number,
    itemIndex: Number,
    multilayerIndex: {
      type: Number,
      default: () => null
    },
    isInCustom: Boolean,
    parentType: String
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: 'UPDATE_JUNCTION_LAYER_ITEM_VALUE',
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE',
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE'
    }),
    ...mapActions({
      updateSystemLayerMaterialItemValue: 'updateSystemLayerMaterialItemValue',
      replaceMaterialFromSystem: 'replaceMaterialFromSystem'
    }),
    updateValueInSystem(value) {
      const { sectorIndex, layerIndex, itemIndex } = this
      this.updateSystemLayerMaterialItemValue({ sectorIndex, layerIndex, itemIndex, value })

      const canJunctionThicknessReplace = this.systemsListWithReplacingProducts
        .find((systemLayers) => systemLayers.id === this.sectorFromState.layers[layerIndex].id)
        ?.items.find((product) => product.id === this.item.id)
        ?.junctionThicknessReplace

      if (canJunctionThicknessReplace) {
        const productId = this.item?.material?.id
        const thickness = value

        this.replaceMaterialFromSystem({ sectorIndex, layerIndex, itemIndex, productId, thickness })
      }
    }
  },
  computed: {
    ...mapState({
      systemsListWithReplacingProducts: state => state.systemsListWithReplacingProducts
    }),
    material() {
      return this.item.material ? this.item.material : this.item
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isInSystem() {
      return this.parentType === 'system'
    },
    getUnit() {
      return this.isComplex
        ? this.$t(`message.units.${this.material.units ? this.material.units : 'mm'}`)
        : this.$t('message.units.mm')
    },
    isMultilayerMode() {
      return this.parentType === 'multilayer'
    },
    isInput() {
      return this.material.valueType === 'input'
    },
    isSelect() {
      return this.material.valueType === 'select'
    },
    isComplex() {
      return this.item.isComplex
    },
    currentOptions() {
      if (this.isInSystem) {
        return this.material.options
      } else {
        return this.isInCustom ? this.material.options : this.material.allowedOptions
      }
    },
    isOptionDisabled() {
      return this.currentOptions?.length === 1
    },
    computedSquare: {
      get() {
        return this.material.square
      },
      set(value) {
        const square = parseFloat(value)
        const { sectorIndex, layerIndex, itemIndex, multilayerIndex } = this

        if (!isNaN(square) && square >= 0) {
          this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE(
            { sectorIndex, layerIndex, itemIndex, multilayerIndex, square }
          )
        }
      }
    },
    computedValue: {
      get() {
        return this.material.value
      },
      set(value) {
        const newInputValue = parseFloat(value)
        if (!isNaN(newInputValue) && newInputValue >= 0) {
          const { sectorIndex, layerIndex, itemIndex, junctionIndex, multilayerIndex } = this

          if (this.isInSystem) {
            this.updateValueInSystem(newInputValue)
          } else if (this.isMultilayerMode) {
            this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE(
              { sectorIndex, layerIndex, itemIndex, multilayerIndex, value: newInputValue }
            )
          } else {
            this.UPDATE_JUNCTION_LAYER_ITEM_VALUE(
              { sectorIndex, layerIndex, itemIndex, junctionIndex, value: newInputValue }
            )
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.layer
  &--input-square
    margin-left: rem(16)
    grid-template-columns: 73% 27%
.elements_multilayer.v-error
  .layer--input-square
    animation: blinking .5s linear infinite
</style>
